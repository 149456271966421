// Navigation //
let navButton = document.querySelector(".nav__button");
let nav = document.querySelector(".nav");
let navItems = document.querySelectorAll(".nav .nav__item a");

function closeNav() {
	navButton.classList.remove("nav__button--open");
	nav.classList.remove("nav--open");
}

navButton.addEventListener("click", (event) => {
	event.stopPropagation(); // Verhindert das Auslösen des document click-Event-Listeners
	navButton.classList.toggle("nav__button--open");
	nav.classList.toggle("nav--open");

	adjustLogoColors();
});

// Schließt NAV nach Link-Click und Scrollt zu Element smooth
navItems.forEach((item) => {
	item.addEventListener("click", function (event) {
		closeModal();

		const targetElement = document.querySelector(event.target.getAttribute('href'));
		if (targetElement) { // Prüfen, ob das Ziel-Element existiert
			event.preventDefault();
			closeNav();
			adjustLogoColors();
			targetElement.scrollIntoView({
				behavior: "smooth"
			});
		}
	});
});

document.addEventListener("click", (event) => {
	// Überprüft, ob weder das navButton-Element noch das nav-Element den Zielknoten des click-Events enthält
	if (!navButton.contains(event.target) && !nav.contains(event.target)) {
		closeNav();
	}
});

const logoElement = document.getElementById('logo_color');
const fontElements = document.querySelectorAll('#logo_color_font, #logo_burger');
const specialElement = document.getElementById('referenzen');

function adjustLogoColors() {
    const changePoint = window.innerHeight;
    const isNavOpen = nav.classList.contains("nav--open");

    if (specialElement) {
        const specialElementPosition = specialElement.getBoundingClientRect().top;
        const isSpecialElementVisible = specialElementPosition <= 1 && specialElementPosition + specialElement.offsetHeight >= 0;

        if (isNavOpen || isSpecialElementVisible) {
            logoElement.style.fill = '#fff';
            fontElements.forEach(el => el.style.fill = '#005972');
        } else if (window.scrollY >= changePoint) {
            logoElement.style.fill = '#005972';
            fontElements.forEach(el => el.style.fill = '#fff');
        } else {
            logoElement.style.fill = '#fff';
            fontElements.forEach(el => el.style.fill = '#005972');
        }
    } else {
        logoElement.style.fill = isNavOpen ? '#fff' : '#005972';
        fontElements.forEach(el => el.style.fill = isNavOpen ? '#005972' : '#fff');
    }
}


// Event Listener, um die Funktion beim Scrollen und beim Laden der Seite auszuführen
window.addEventListener('scroll', adjustLogoColors);
window.addEventListener('load', adjustLogoColors);


// AOS //
// AOS.init();


// Mail

let user = 'bettina.kuschek'; 
let domain = 'ksw-kom.de'; 
let emailElements = document.querySelectorAll('.mail');

emailElements.forEach(function(element) {
    element.innerHTML = '<a href="mailto:' + user + '@' + domain + '">' + user + '(at)' + domain + '</a>';
});